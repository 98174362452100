import { Trans } from "react-i18next";

import { Box, Button, Flex, Image, Link } from "@chakra-ui/react";

import ParentPortalLayout from "@layouts/ParentPortalLayout.tsx";

import { Text } from "@components/Text";

import img404 from "../../assets/img/404.svg";
import img404Bg from "../../assets/img/gray-leaf.svg";

const NotFoundPage = () => {
    return (
        <ParentPortalLayout>
            <Flex minHeight="calc(100vh - 4rem)" w="full" py={8} px={6}>
                <Flex
                    m="auto"
                    direction="column"
                    justify="center"
                    align="center"
                    textAlign="center"
                >
                    <Text type="heading" fontSize={40} mb={14} color="gray.900">
                        <Trans>
                            Hmm, looks like this page is missing: 404 Error
                        </Trans>
                    </Text>
                    <Box
                        position="relative"
                        mb={12}
                        _after={{
                            content: "''",
                            position: "absolute",
                            width: "320px",
                            height: "190px",
                            backgroundImage: img404Bg,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            left: "50%",
                            top: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            zIndex: "-1"
                        }}
                        isolation="isolate"
                        data-testid="background-img-404"
                    >
                        <Image
                            src={img404}
                            alt=""
                            maxW={203}
                            maxH={190}
                            data-testid="img-404"
                        />
                    </Box>
                    <Text fontSize={16} maxWidth="76ch" mb={9}>
                        <Trans>
                            Oops! Looks like you've taken a wrong turn. The page
                            you're looking for is playing hide and seek. Don't
                            worry, our developers are on the case. In the
                            meantime, why not explore the rest of our site? You
                            might just discover something awesome!
                        </Trans>
                    </Text>
                    <Link href="/">
                        <Button
                            as="span"
                            colorScheme="ebonyClay"
                            _hover={{
                                textDecoration: "none"
                            }}
                            fontSize={16}
                            fontFamily="infini, sans-serif"
                        >
                            <Trans>Return to Homepage</Trans>
                        </Button>
                    </Link>
                </Flex>
            </Flex>
        </ParentPortalLayout>
    );
};

export default NotFoundPage;
